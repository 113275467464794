import React, { useContext, useEffect, useRef, useState } from 'react'
import 'overlayscrollbars/overlayscrollbars.css';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import ProvinceContext from '../contexts/ProvinceContext';

export default function ProvinceList() {
  const delay = 5000;
  const { ProvinceListData, provinceIndex, updateProvinceIndex } = useContext(ProvinceContext);
  const [hospitalNumber, setHospitalNumber] = useState(0);
  const timeoutRef = useRef(null);
  let counterIndex = 0;

  function startAutoInterval() {
    timeoutRef.current = setInterval(() => {
      // Update the data here
      if (counterIndex > ProvinceListData.data.length - 1) {
        counterIndex = 0;
      }
      updateProvinceIndex(counterIndex);
      counterIndex++;

    }, delay);
  }

  useEffect(() => {
    if (provinceIndex !== null) {
      setHospitalNumber(ProvinceListData.data[provinceIndex].hospitals.length);
      return () => {
        // console.log('Cleaning up the side effect');
      };
    }
  }, [provinceIndex]);

  useEffect(() => {
    startAutoInterval();
    return () => clearInterval(timeoutRef.current); // Clear the interval on component unmount
  }, []);

  const handleClick = (index) => {
    if (timeoutRef.current) {
      clearInterval(timeoutRef.current);
    }
    startAutoInterval();
    updateProvinceIndex(index);
    setHospitalNumber(ProvinceListData.data[index].hospitals.length);
    counterIndex = index;
  };

  return (
    <div className="province-ul-container fade-in-right">
      <div className="province-top-bar">
        <svg width="200" height="85">
          <line x1="150" y1="65" x2="190" y2="65" stroke="#007a89" strokeWidth="1"></line>
          <line x1="95" y1="80" x2="175" y2="80" stroke="#007a89" strokeWidth="1"></line>
          <line x1="150" y1="65" x2="135" y2="79" stroke="#007a89" strokeWidth="1"></line>
        </svg>
      </div>
      <OverlayScrollbarsComponent
        style={{  }}
        options={{ scrollbars: { theme: 'os-theme-light' } }}
        defer
      >
        <ul className="province-list">
          {ProvinceListData.data.map((item, index) => (
            <li onClick={() => handleClick(index)} key={index}>
              <div className={`${index === provinceIndex ? 'wide' : ''}`}>
                {(() => {
                  if (index === provinceIndex) {
                    return <span className="province-list-hospital-count">{hospitalNumber}</span>;
                  } else {
                    return null; // or any other fallback content
                  }
                })()}
                <span>{item.name}</span>
              </div>
            </li>
          ))}
        </ul>
      </OverlayScrollbarsComponent>
    </div>
  );
}