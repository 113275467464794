import { useEffect, useState } from "react";
import ProvinceDetail from "./components/ProvinceDetail";
import ProvinceList from "./components/ProvinceList";
import * as ProvinceListData from "./files/provinces.json";
import { ProvinceProvider } from "./contexts/ProvinceContext";
import LineConnection from "./components/LineConnection";
import MapMarker from "./components/MapMarker";
import ParticleEarth from "./components/ParticleEarth";
import FullScreenButton from "./components/FullScreenButton";
import PageLoader from "./components/PageLoader";
import AppTitle from "./components/AppTitle";

function App() {
  const [isGlobeLoaded, setIsGlobeLoaded] = useState(false);
  const [provinceIndex, setProvinceIndex] = useState(null);
  const [provinceCoordinates, setProvinceCoordinates] = useState([0, 0]);
  const updateProvinceIndex = (newValue) => {
    setProvinceIndex(newValue);
  }

  const updateGlobeLoadStatus = (newValue) => {
    setIsGlobeLoaded(newValue)
  }

  const updateProvinceCoordinates = (x, y) => {
    setProvinceCoordinates([x, y]);
  }

  return (
    <div className="App" style={{ overflow: `hidden` }}>
      <ProvinceProvider value={{ ProvinceListData, provinceIndex, updateProvinceIndex, provinceCoordinates, updateProvinceCoordinates, isGlobeLoaded, updateGlobeLoadStatus }}>
        <PageLoader>
          <FullScreenButton />
          <AppTitle />
          <ParticleEarth />
          <LineConnection />
          <MapMarker></MapMarker>
          <ProvinceDetail></ProvinceDetail>
          <div className="province-list-container">
            <div className="province-vertical-line"></div>
            <ProvinceList></ProvinceList>
          </div>
        </PageLoader>
      </ProvinceProvider>
    </div>
  );
}

export default App;
