import { useContext, useEffect, useRef, useState } from "react";
import ProvinceContext from "../contexts/ProvinceContext";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

export default function ProvinceDetail() {
  const provinceNumberSpan = useRef(null);
  const provinceTitleDiv = useRef(null);
  const { ProvinceListData, provinceIndex } = useContext(ProvinceContext);
  const [hospitalList, setHospitalList] = useState([]);
  const [provinceTitle, setProvinceTitle] = useState("");
  const [numberOfHospitals, setNumberOfHospitals] = useState(null);
  useEffect(() => {
    if (provinceIndex !== null) {
      const currentDiv = provinceNumberSpan.current;
      currentDiv.classList.add('slide-up');
      const provinceTitleElement = provinceTitleDiv.current;
      provinceTitleElement.classList.add('rotate-slide-up');
      setTimeout(() => {
        currentDiv.classList.remove('slide-up');
        provinceTitleElement.classList.remove('rotate-slide-up');
      }, 500);
      setHospitalList(ProvinceListData.data[provinceIndex].hospitals);
      setProvinceTitle(ProvinceListData.data[provinceIndex].name);
      setNumberOfHospitals(ProvinceListData.data[provinceIndex].hospitals.length);
    }
    return () => {
      // console.log('Cleaning up the side effect');
    };
  }, [provinceIndex]);
  return (
    <>
      {provinceIndex !== null && (
        <div className="province-detail-container fade-in">
          <div className="province-number-block">
            <div className="start-line-connection"></div>
            <div className="province-number"><span ref={provinceNumberSpan}>{numberOfHospitals}</span></div>
            <div className="province-title"><span ref={provinceTitleDiv}>{provinceTitle}</span></div>
          </div>
          <div className="hospital-list-container">
            <div className="hospital-container-top-bar"></div>
            <OverlayScrollbarsComponent
              element="ul"
              style={{}}
              options={{ scrollbars: { theme: 'os-theme-light' } }}
              defer
            >
              {hospitalList.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </OverlayScrollbarsComponent>
            <div className="hospital-container-bottom-bar"></div>
          </div>
        </div>
      )}
    </>
  );
}