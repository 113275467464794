import { useContext, useEffect, useRef, useState } from "react";
import RawEarth from "../img/raw-earth.png";
import ProvinceContext from "../contexts/ProvinceContext";

const PageLoader = ({ children }) => {
  const { updateGlobeLoadStatus } = useContext(ProvinceContext);
  const [isLoading, setIsLoading] = useState(true);
  const [showChildren, setShowChildren] = useState(false);
  const loaderRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowChildren(true);
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const image = new Image();
    image.src = RawEarth;

    image.onload = () => {
      if (loaderRef.current) {
        loaderRef.current.classList.add('fade-out');
      }
      setTimeout(() => {
        updateGlobeLoadStatus(true);
        setIsLoading(false);
      }, 1000);
    };

    image.onerror = () => {
      setIsLoading(false);
    };

    return () => {
      // Clean up the image object if component unmounts
      image.onload = null;
      image.onerror = null;
    };
  }, []);
  return (
    <>
      {isLoading && (
        <div
          ref={loaderRef}
          style={{
            zIndex: '200',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          {/* Replace this with your custom loader component */}
          <div className="loader"></div>
        </div>
      )}
      {!isLoading && (
        <>
          {showChildren && children}
        </>
      )}
    </>
  )
}

export default PageLoader;