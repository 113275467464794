import { useContext } from "react";
import ProvinceContext from "../contexts/ProvinceContext";

export default function MapMarker() {
  const { ProvinceListData, provinceIndex } = useContext(ProvinceContext);
  return (
    <>
      {provinceIndex !== null && (
        <div id="map-marker" className="map-markder-container">
          <div className="map-title">{ProvinceListData.data[provinceIndex].name}</div>
          <div class="number-circle-block">
            <div className="outer-circle"></div>
            <div className="inner-circle"></div>
            <div id="map-marker-circle" className="map-markder">
              <span>{ProvinceListData.data[provinceIndex].hospitals.length}</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}