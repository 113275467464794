import Particles from "react-particles";
import particlesConfig from "./config/particles-config";
import { loadFull } from "tsparticles";
import { useCallback, useContext, useEffect, useState } from "react";
import 'leaflet/dist/leaflet.css';
import 'proj4leaflet';
import ProvinceContext from "../contexts/ProvinceContext";
import ChinaBoundryCustom from "./particles/ChinaBoundryCustom";
import RawEarth from "../img/raw-earth.png";

export default function ParticleEarth() {
  const { ProvinceListData, provinceIndex, updateProvinceCoordinates, isGlobeLoaded } = useContext(ProvinceContext);
  const [imageWidth, setImageWidth] = useState(0);
  const particlesInit = useCallback(async engine => {
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(container => {
  }, []);

  const handleResize = () => {
    const imageWidth = document.getElementById('raw-earth').clientWidth;
    // resize the .particle-earth-main
    document.getElementById('particle-earth-main').style.width = imageWidth + 40 + "px";
    const chinaSvg = document.getElementById('china-svg');
    chinaSvg.style.width = imageWidth;
    setImageWidth(imageWidth);
  };

  useEffect(() => {
    handleResize();
  }, []);
  
  useEffect(() => {

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (provinceIndex !== null) {
      const circle = document.querySelector(`circle[data-province="${ProvinceListData.data[provinceIndex].province}"]`);
      if (circle) {
        const rect = circle.getBoundingClientRect();
        const x = rect.left + window.scrollX + rect.width / 2;
        const y = rect.top + window.scrollY + rect.height / 2;
        const cx = x
        const cy = y;
        updateProvinceCoordinates(cx, cy);
      }
    }

  }, [provinceIndex]);

  return (
    <div>
      {/* <div style={{ position: 'fixed', zIndex: 400, width: '100%', height: '100%' }} onClick={handleClick}>
        Click anywhere to get the mouse position.
      </div> */}
      {/* <div><img src={chinaProvinceMap} style={{position: 'fixed', right: '0px', zIndex: '500', width: '500px'}} alt="test"/></div> */}
      <div id="particle-earth-main" className="particle-earth-main fade-in-left">
        <div id="raw-earth-container" class="raw-earth-container">
          <img id="raw-earth" src={RawEarth} alt="earth" />
          <div id="chinaBoundryOutBound" className="chinaBoundryOutBound">
            <ChinaBoundryCustom />
          </div>
        </div>
        <div className="earth-glow"></div>
        <div className="canvasBlock">
          <div className="canvasContainer">
            <Particles
              className="canvassParticleID"
              canvasClassName="canvasParticle"
              id="tsparticles"
              init={particlesInit}
              loaded={particlesLoaded}
              options={particlesConfig}>
            </Particles>
          </div>
        </div>
      </div>
    </div>
  );
}