import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";

const AppTitle = () => {
  const globeTitleRef = useRef(null);
  const [divWidth, setDivWidth] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      if (globeTitleRef.current) {
        const imageWidth = document.getElementById('raw-earth').clientWidth;
        const windowWidth = document.documentElement.clientWidth;
        const maxWidth = windowWidth - imageWidth - 200; // The same value as the max-width in CSS
        if (windowWidth > 1400) {
          globeTitleRef.current.style.fontSize = "28px";
          globeTitleRef.current.style.lineHeight = "40px";
        } else {
          globeTitleRef.current.style.fontSize = "20px";
          globeTitleRef.current.style.lineHeight = "26px";
        }
        setDivWidth(maxWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial width

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (globeTitleRef.current) {
      globeTitleRef.current.style.width = `${divWidth}px`;
    }
  }, [divWidth]);
  
  return (
    <>
      <div ref={globeTitleRef} className="globe-title">
        天玑骨科机器人的临床应用已覆盖170余家 医疗机构，30个省、自治区、直辖市，手术 量突破40000例。
      </div>
    </>
  );
}

export default AppTitle;