import { useEffect, useState } from "react";

export default function ChinaBoundryCustom({ calculateScale }) {
  const [parentWidth, setParentWidth] = useState(0);
  const [parentHeight, setParentHeight] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      // const parentDiv = document.getElementById('svgCustomChinaMapContainer');
      const parentDiv = document.getElementById('chinaBoundryOutBound');
      setParentWidth(parentDiv.offsetWidth);
      setParentHeight(parentDiv.offsetHeight);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleCalculateScale = () => {
    const svgWidth = 850; // Replace with the original SVG width
    const svgHeight = 850; // Replace with the original SVG height

    const scaleX = parentWidth / svgWidth;
    const scaleY = parentHeight / svgHeight;

    return `scale(${scaleX} ${scaleY})`;
  };
  
  return (
    <svg id="china-svg" className="svgCustomChinaMap" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 500">
      <g className="">
      {/* <g className="svgCustomChinaMapGroup"> */}
      {/* <g transform={handleCalculateScale()}> */}
        <path
          d="M604 135 L603 161 L594 175 L591 198 L565 217 L553 230 L567 235 L564 259 L569 276 L584 287 L598 313 L591 340 L586 358 L580 372 L576 384 L563 390 L548 399 L538 410 L531 432 L526 439 L519 424 L519 421 L509 420 L497 424 L490 429 L469 424 L451 414 L440 412 L423 404 L420 386 L415 374 L399 372 L391 372 L386 363 L386 358 L369 360 L353 365 L317 365 L298 365 L276 362 L257 351 L241 339 L227 321 L214 299 L187 285 L159 281 L157 270 L157 238 L157 199 L170 189 L181 183 L206 173 L220 166 L232 158 L247 157 L253 160 L268 176 L284 193 L307 205 L339 224 L374 230 L422 225 L430 219 L445 207 L471 194 L489 176 L499 163 L504 157 L508 149 Z"
          fill="none"
          stroke="none"
        />
        <circle data-province="1" cx="555" cy="311" r="3" fill="none" />
        <circle data-province="2" cx="516" cy="219" r="3" fill="none" />
        <circle data-province="3" cx="462" cy="340" r="3" fill="none" />
        <circle data-province="4" cx="572" cy="360" r="3" fill="none" />
        <circle data-province="5" cx="333" cy="246" r="3" fill="none" />
        <circle data-province="6" cx="543" cy="388" r="3" fill="none" />
        <circle data-province="7" cx="483" cy="376" r="3" fill="none" />
        <circle data-province="8" cx="463" cy="305" r="3" fill="none" />
        <circle data-province="9" cx="516" cy="238" r="3" fill="none" />
        <circle data-province="10" cx="512" cy="298" r="3" fill="none" />
        <circle data-province="11" cx="576" cy="168" r="3" fill="none" />
        <circle data-province="12" cx="513" cy="324" r="3" fill="none" />
        <circle data-province="13" cx="506" cy="351" r="3" fill="none" />
        <circle data-province="14" cx="572" cy="197" r="3" fill="none" />
        <circle data-province="15" cx="565" cy="266" r="3" fill="none" />
        <circle data-province="16" cx="539" cy="350" r="3" fill="none" />
        <circle data-province="17" cx="561" cy="217" r="3" fill="none" />
        <circle data-province="18" cx="479" cy="208" r="3" fill="none" />
        <circle data-province="19" cx="413" cy="272" r="3" fill="none" />
        <circle data-province="20" cx="491" cy="253" r="3" fill="none" />
        <circle data-province="21" cx="472" cy="274" r="3" fill="none" />
        <circle data-province="22" cx="588" cy="293" r="3" fill="none" />
        <circle data-province="23" cx="406" cy="313" r="3" fill="none" />
        <circle data-province="24" cx="566" cy="236" r="3" fill="none" />
        <circle data-province="25" cx="293" cy="329" r="3" fill="none" />
        <circle data-province="26" cx="226" cy="231" r="3" fill="none" />
        <circle data-province="27" cx="428" cy="367" r="3" fill="none" />
        <circle data-province="28" cx="532" cy="268" r="3" fill="none" />
        <circle data-province="29" cx="593" cy="324" r="3" fill="none" />
      </g>
    </svg>
  );
}