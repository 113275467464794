import React, { useContext, useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import ProvinceContext from '../contexts/ProvinceContext';

const LineConnection = () => {
  const { provinceCoordinates, provinceIndex } = useContext(ProvinceContext);
  const lineRef = useRef(null);
  const svgRef = useRef(null);

  useEffect(() => {
    if (provinceIndex !== null) {
      // Create a D3 selection for the line
      const line = d3.select(lineRef.current);
      const mapMarker = d3.select('#map-marker')
      const provinceNumberDiv = d3.select(".start-line-connection");
  
      // Function to handle mouseenter event on list items
      const handleMouseClick = function () {
        // Get the position of the target div
        const svgElement = svgRef.current;
        svgElement.style.width = '100%';
        svgElement.style.height = '100vh';
        svgElement.style.zIndex = '0';
        const provinceNumber = provinceNumberDiv.node().getBoundingClientRect();
        const start = {
          x: provinceNumber.x,
          y: provinceNumber.y + provinceNumber.height / 2
        };
        const end = {
          x: provinceCoordinates[0],
          y: provinceCoordinates[1]
        };
  
        // Draw the line
        line
          .transition()
          .duration(250) // Animation duration in milliseconds
          .attr('x1', start.x)
          .attr('y1', start.y)
          .attr('x2', parseInt(end.x) + 37)
          .attr('y2', end.y)
          .attr('stroke', '#4ff6f9')
          .attr('stroke-width', 1);
  
        const divWidth = parseInt(mapMarker.style('width'), 10);
        const divHeight = parseInt(mapMarker.style('height'), 10);
        const centerX = end.x - divWidth / 2; // Calculate the x-coordinate of the center
        const centerY = end.y - divHeight / 2 - 25;
  
        mapMarker
          .transition()
          .duration(250) // Animation duration in milliseconds
          .style("position", "absolute")
          .style('transform', `translate(${centerX}px, ${centerY}px)`)
      };
  
      handleMouseClick();
    }
    // Cleanup event listeners on component unmount
    return () => {
      // listElement.on('click', null);
    };
  }, [provinceIndex, provinceCoordinates]);

  return (
    <>
      <svg className="svgLine" ref={svgRef}>
        <line ref={lineRef} />
      </svg>
    </>
  );
};

export default LineConnection;